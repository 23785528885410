<template>
  <div>

    <div class="page-container prepare">
      <div class="page-header">
        <div class="logo">
          <img src="../../../asset/image/oalogo.png" alt="">
        </div>
        <div class="title">（信创OA）工单报送系统</div>
      </div>
      <van-form @submit="onSubmit" ref="formRef">

        <van-cell-group inset>
          <div class="mktitle">提交工单</div>
          <div class="item required">
            <div class="label">问题描述:</div>
            <div class="input">
          <van-field placeholder="请填写问题描述"
                     class="description"
                     v-model.trim="form.description"
                     required
                     autosize
                     clearable
                     show-word-limit
                     type="textarea"
                     maxlength="500"
                     name="description"
                     :rules="[{ required: true, message: '请填写问题描述' }]"/>
            </div>
          </div>
          <div class="item required noborder">
            <div class="label">文件上传:</div>
            <div class="input">
          <van-field name="files" :rules="[{ required: true, message: '请上传附件' }]">
            <template #input>
              <van-uploader multiple
                            v-model="form.files"
                            accept=".doc,.docx,.zip,.rar,.jpg,.png,.jpeg,.xlsx,.xls"
                            :max-count="10"
                            :max-size="1024 * 50 * 1024"
                            @oversize="onOversize"/>
            </template>
          </van-field>
            </div>
          </div>
              <div class="item required">
                <div class="label">验证码:</div>
                <div class="input">
                  <van-row>
                    <van-col span="16">
                      <van-field
                          placeholder="请输入验证码"
                          :left-icon="require('../../../asset/form/captcha.png')"
                          name="captcha"
                          required
                          clearable
                          v-model.trim="form.captcha"
                          :rules="[{ validator: validationCaptcha, message: '请输入正确内容' }]"/>
                    </van-col>
                    <van-col span="8">
                      <van-image width="108" height="40" :src="captchaUrl" @click="getCaptcha"/>
                    </van-col>
                  </van-row>

                </div>
              </div>

          <van-cell>
            <van-button type="primary" native-type="submit" class="submitbut" :loading="loading">提交
            </van-button>
          </van-cell>
        </van-cell-group>
      </van-form>
    </div>
    <div class="page-background"></div>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue";
import {Dialog, Toast} from "vant";
import {useRouter} from "vue-router";
import axios from "@/plugin/axios";
import {createWorkorder} from "../../../api/workorder";
import {getCurrentTime} from "../../../plugin/tools";

onMounted(() => {
  validationUrlParam();
  const date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth().toString().padStart(2,0);
  let d = date.getDate().toString().padStart(2,0);
  let hour = date.getHours().toString().padStart(2,0);
  let m = date.getMinutes().toString().padStart(2,0)
  //form.appointmentTime = year+"-"+month+ "-" + d + " " +hour+":"+m;
  getCaptcha();
});

const router = useRouter();
const formRef = ref(null);

const captchaUrl = ref("");
// 验证码错误信息提示
const errorMessage = ref("");
// 时间选择器
let showPicker = ref(false);
// 控制验证码的弹窗
const show = ref(false);

const loading = ref(false);

const query = getUrlParam();
// 表单
const form = reactive({
  description: "",
  captcha: "",
  captchaId: "",
  files: []
});

/**
 * 限制上传图片的大小
 */
const onOversize = () => {
  Toast("文件大小不能超过 10M");
};





/**
 * 刷新验证码
 */
const getCaptcha = () => {
  axios.get("/workorder/unassigned-workorder/captcha").then(res => {
    captchaUrl.value = res.data.base64Image;
    form.captchaId = res.data.captchaId;
    errorMessage.value = "";
  }).catch(error => {
    console.warn("Get workorder prepare captcha fail", error.response);
  });
};

/**
 * 校验验证码
 * @param value 验证码
 */
const validationCaptcha = value => {
  if (!!value) {
    if (!!errorMessage.value) {
      return errorMessage.value;
    } else {
      return true;
    }
  } else {
    return "请输入验证码";
  }
};

const handle = (formData) => {
  createWorkorder(formData).then(response=>{
    loading.value = false;
    Dialog.alert({
      title: "提交成功",
      confirmButtonText: "确定"
    }).then(() => {
      form.description = "";
      form.captcha = "";
      form.captchaId = "";
      form.files = [];
      //router.push({path: "/", query: getUrlParam()});
    });
  }).catch(error=>{
    loading.value = false;
    if (error.status === 40000 || error.status === 40400) {
        errorMessage.value = error.data;
        formRef.value.validate("captcha");
        getCaptcha();
    }
  });

  // axios.post("/workorder/workorder/prepare", formData).then(response => {
  //   loading.value = false;
  //   Dialog.alert({
  //     title: "提交成功",
  //     confirmButtonText: "返回首页"
  //   }).then(() => {
  //     router.push({path: "/", query: getUrlParam()});
  //   });
  // }).catch(error => {
  //   loading.value = false;
  //   if (error.status === 40000 || error.status === 40400) {
  //     errorMessage.value = error.data;
  //     formRef.value.validate("captcha");
  //     getCaptcha();
  //   }
  // });


}





// clientDepartmentId:"556605283623899136" //客户单位 ID
// clientId:"556605425953411072" //客户 ID
// deadLine:"2024-11-30 17:10"
// description:"测试"
// files:[]
// logicType:"ops" //运维工单
// origin:"default"
// priority:"5" //工单优先级
// resourceId:"593918131571920896" // 合同号
// type:"oawh" // 服务类型


/**
 * 提交表单
 */
const onSubmit = () => {
  let formData = new FormData();
  formData.append("appointment", getCurrentTime());
 // formData.append("deadLine", getCurrentTime());
  formData.append("clientDepartmentId", "601636543223959552");
  formData.append("clientId", "601636669627699200");
  formData.append("description", form.description);
  formData.append("logicType", "ops");
  formData.append("origin", "default");
  formData.append("priority", 5);
  formData.append("resourceId", "601910535126126592");
  formData.append("captchaId", form.captchaId);
  formData.append("captcha", form.captcha);
  formData.append("type", "oawh");
  if (form.files.length > 0) {
    form.files.forEach(item => {
      formData.append("files", item.file);
    });
  }
  loading.value = true;
  handle(formData) // 提交表单

};

</script>

<style scoped>

.nav-left-img {
  width: 30px;
}

/deep/ .van-nav-bar__title {
  color: white;
}



.description /deep/ .van-field__left-icon {
  align-items: start;
}

/deep/ .van-nav-bar--fixed {
  background-color: #0546EF;
}
.submitbut{
  width: 150px;
  display: block;
  margin: 30px 0 60px 40px;
}
.item{
  margin: 0 5%;
}
.item .label {
  height: 50px;
  line-height: 50px;
}
.required .label:before{
  content: '*';
  color: red;
  margin-right: 5px;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  transform: translateY(-1px);
  font-weight: bold;
}

.item .input {
  border: 1px #ddd solid;
  border-radius: 5px;
  overflow: hidden;
}
.noborder .input{
  border: none;
}
.page-container{
  margin: 0 auto;
  width: 1200px;
}
.page-header{
  margin: var(--van-cell-group-inset-padding);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.mktitle{
  font-size: 16px;
  padding: 20px;
  font-weight: bold;
}
.page-header .logo{
  padding-bottom: 20px;
}
.page-header .logo img{
  height: 35px;
}
.page-header .title{
  font-family: Microsoft YaHei;
  font-size:14px;
  color: #FFFFFF;
  padding-bottom: 20px;
}


.page-background {
  z-index: -1;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  background: #1f8be8 url("../../../asset/image/oabj.jpg") no-repeat right bottom;
  background-size: cover;
}



@media (max-width: 750px) {
  .page-container{
    margin: 0 auto;
    width: auto;
  }
  .page-header{
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  .page-header .logo{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    padding: 0;
  }
  .page-header .title{
    padding: 0;
    height: 60px;
    line-height: 45px;
    color: #a7caf2;
    font-weight: bold;
  }
  .submitbut{
    width: 100%;
    display: block;
    margin: 0 0 20px 0;
  }
}

</style>
